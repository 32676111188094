<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :scroll="{x: true}"
            :columns="columns"
            :get_table_list="this.get_table_list"
            ref="send"
			@changeStatus='changeStatus'
            :expandRowByClick="false"
            @expandedRowsChange="handleExpandedRowsChange($event)"
            :expandedRowRender="true"
			:submit_preprocessing="submit_preprocessing"
            :slot_table_list="['operation','operations']"
            :rowKey="'id'"
        >
			<template slot="right_btn">
			    <!-- <a v-if="this.$store.state.page_auth.export" @click="report()">
					<a-button class="a_btn">导出</a-button>
				</a> -->
                <a :href="'/manage/crm.electronic/exportSendOrder' | export_form" target="_blank">
                    <a-button class="a_btn">导出</a-button>
                </a>
			</template>
            <template slot="operation" slot-scope="data">
			    <a @click="go_detail(data.record)">查看</a>
                <a-divider type="vertical" v-if="data.driver_notify_status<1"/>
			    <a @click="push_again(data.record,'driver')" v-if="data.driver_notify_status<1">司机推送</a>
			</template>
            <template slot="expandedRowRender" slot-scope="data">
                <a-table
                    :columns="columns_sub"
                    :pagination="false"
                    :dataSource="data.record.list"
                    style="margin: 0"
                >
                    <!-- <template slot="footer" slot-scope>
                        <div
                            style="text-align:right"
                        >共{{data.record.goods.goods_num}}件商品，总计：¥{{data.record.goods.goods_total}}</div>
                    </template> -->
                    <template
                        slot="emptyBarrelInfo"
                        slot-scope="text,record"
                    >{{record.emptyBarrelInfo ? '桶装' : '瓶装'}}</template>
                    
                    <template slot="operations" slot-scope="record">
                        <a @click="go_detail(record)">查看</a>
                        <a-divider type="vertical" v-if="record.notify_status<1"/>
			            <a @click="push_again(record,'seller')"  v-if="record.notify_status<1">经销商推送</a>
                    </template>
                </a-table>
            </template>
        </TableList>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { get_send_list, get_send_shipment ,getStatusSelect,pushTemplateNotice} from "@/api/push";
import TableList from "@/components/TableList";
const columns = [
    {
        title: "仓管",
        dataIndex: "username",
    },
    {
        title: "出货仓库",
        dataIndex: "storage_name",
    },
    {
        title: "承运商",
        dataIndex: "merchant_name",
    },
    {
        title: "司机",
        dataIndex: "chauffeur",
    },
    {
        title: "司机电话",
        dataIndex: "chauffeur_mobile",
    },
    {
        title: "车牌",
        dataIndex: "truck_license",
    },
    {
        title: "出库单编号",
        dataIndex: "outcome_ids",
    },
    {
        title: "状态",
        dataIndex: "status_name",
    },
    {
        title: "创建时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    }
];

const columns_sub = [
    {
        title: "经销商",
        dataIndex: "seller_name",
    },
    {
        title: "出库单编号",
        dataIndex: "outcome_id",
    },
    {
        title: "出库单类型",
        dataIndex: "emptyBarrelInfo",
        scopedSlots: {
            customRender: "emptyBarrelInfo"
        }
    },
    // {
    //     title: "货物明细编号",
    //     dataIndex: "shipment_id",
    // },
    {
        title: "货物总数",
        dataIndex: "total_count",
    },
    {
        title: "出货时间",
        dataIndex: "shipment_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operations"
        }
    }
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        TableList
    },
    data() {
        return {
			submit_preprocessing: {
			    array_to_string: ['department']
			},
            get_table_list: get_send_list,
            columns,
            columns_sub,
            visible: false,
		
            form_data_seo: {
                list: [           
                    {
                        type: "text",
                        name: "keyword",
                        title: "关键词",
                        placeholder: '仓管/仓库/承运商/司机/出库单号',
                        options: {},
						flag:'send'
					},
                    // {
                    //     type: "text",
                    //     name: "outcome_id",
                    //     title: "出库单号",
                    //     placeholder: "请输入出库单号",
                    //     options: {
                    //         initialValue:''
                    //     }
                    // },
					// {
					//     type: "tree-select",
					//     name: "department",
					//     title: "部门",
					//     options: {},
					//     treeData: [],
					//     multiple: true
					// },
                    // {
                    //     type: "text",
                    //     name: "customer",
                    //     title: "客户",
                    //     placeholder: '客户名称/ID/地址',
                    //     options: {
                    //     }
                    // },
					{
                        type: "select",
                        name: "status",
                        title: "状态",
                        mode: "default",
                        options: {},
                        list: []
                    },
                    {
                        type: "range_date",
                        name: "range_date",
                        title: "创建时间",
                        options: {},
                        start: {
                            name: 'start_time'
                        },
                        end: {
                            name: 'end_time'
                        },
                    },
                ],
                ...this.$config.form_data_seo
            },
            form_data: {}
        };
    },
	async created() {
		
        this.get_select();
        let query = this.$route.query;
		if (query.keyword) {
		    this.form_data_seo.list.forEach(item => {
		        if (item.name == 'keyword') {
		            item.options.initialValue = query.keyword.toString();
		        }
		    });
		}
		
		this.$nextTick(()=>{
			this.$refs.send.fixed_seo_data = {
				...query,
			}
			 this.$refs.send.get_list();
		})
        
	},
    mounted() {
        // window.addEventListener('popstate', this.handleBackSendButton,false);
      },
	  activated() {
	  		this.$refs.send.get_list();
	  },
    methods: {
		changeStatus(info){
			this.$refs.send.fixed_seo_data = {}
			this.form_data_seo.list.forEach(item => {
			    if (item.name == 'keyword') {
			        item.options.initialValue =''
			    }
			});
			this.$refs.send.get_list();
			this.$forceUpdate()
		},
    	handleBackSendButton(){
    			this.$refs.send.get_list();
    	},
        async handleExpandedRowsChange(e) {

            e.forEach(item => {
                get_send_shipment({
                        data: {
                            id: item,
                        }
                    }).then(res => {
                        let list = [];
                        this.$refs.send.list.forEach((vo, index) => {
                            if (vo.id == item) {
                                vo.list = res.data.list
                            }
                            list.push(vo)
                        })
                        this.$refs.send.set_data('list', list)
                    })
                
            })
        },
		go_detail(e) {
            this.$keep_route.add(this.$options.name);
			this.$router.push({
				path:"/send/details",
				query:{
					id:e.id,
                    sales_seller_id:e.sales_seller_id?e.sales_seller_id:''
				}
			});
		},
        get_select() {
            getStatusSelect().then(res => {
                this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'status', 'list', res.data.list)
            })
        },
        push_again(data,type) {
            pushTemplateNotice({
                data:{
                    id:data.id,
                    sales_seller_id:data.sales_seller_id?data.sales_seller_id:'',
                    type:type
                },
                Toast:true
            }).then(res=>{
                this.$refs.send.get_list();
            })
        },
        report() {
            
        }
    }
};
</script>

<style lang="less">
@import url("../../../assets/less/app.less");
</style>